@font-face {
    font-family: 'SF Pro';
    font-weight: 200;
    src:  url('fonts/SF-Pro-Display-Ultralight.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 300;
    src:  url('fonts/SF-Pro-Display-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 400;
    src:  url('fonts/SF-Pro-Display-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 500;
    src:  url('fonts/SF-Pro-Display-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 600;
    src:  url('fonts/SF-Pro-Display-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 700;
    src:  url('fonts/SF-Pro-Display-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 800;
    src:  url('fonts/SF-Pro-Display-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 200;
  src:  url('fonts/Manrope-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  src:  url('fonts/Manrope-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src:  url('fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src:  url('fonts/Manrope-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  src:  url('fonts/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  src:  url('fonts/Manrope-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  src:  url('fonts/Manrope-ExtraBold.ttf') format('truetype');
}

/* address search style */
.select-address-autocomplete>div {
    margin-top: 10px;
    margin-left: 10px;
    background-color: rgb(244, 246, 249);
    border-radius: 6px;
    border-color: #82CB43;
    font-family: 'SF Pro';
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    opacity: 1 !important;
  }
  
  .select-address-autocomplete.address>div {
    background-color: white;
    border-color: #B9B9BB;
    height: 44px;
  }
  
  .select-address-autocomplete.address>div:hover {
    border-color: #82CB43 !important;
    box-shadow: none;
  }
  
  .select-address-autocomplete.address [class$="placeholder"] {
    color: #A09C9C;
    font-size: 15px !important;
    font-weight: 300;
  }
  
  .address-input-wrapper {
    display: flex;
    align-items: center;
  }
  
  div.css-166bipr-Input input {
    opacity: 1 !important;
  }
  
  div.css-1cfo1cf input {
    opacity: 1 !important;
  }