.MuiAccordionSummary-expandIcon {
  transform: rotate(270deg) !important;
  background-color: #ffffff !important;
  border-radius: 7px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  padding: 9px !important;
}

.MuiAccordionSummary-expandIcon:hover {
  transform: rotate(270deg) !important;
  background-color: #eeeef2 !important;
  border-radius: 7px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  padding: 9px !important;
}

.menuitem_background {
	background: white;
}

.menuitem_background.disabled {
	background: #d7d5d5 !important;
}

.status-header-style {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-right: 62px;
}

.status-body-style {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 20px;
	overflow-x: hidden;
}

.orders-table-header {
	min-height: 20px;
	margin-bottom: 7px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.orders-table-body {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
}

.orders-table-header .child,
.orders-table-body .child {
	overflow-wrap: anywhere;
  display: flex;
  cursor: pointer;
  min-width: var(--width);
  max-width: var(--width);
}

.orders-table-header .child .text,
.orders-table-body .child .text,
.orders-table-header .header-sort {
	display: flex;
  overflow-x: hidden;
  cursor: pointer;
}

.orders-table-header .child .text {
	font-weight: 590;
  font-size: 15px;
  color: rgba(60, 60, 67, 0.6);
}

.orders-table-body .child .text {
	font-weight: 400;
  font-size: 15px;
  color: rgba(60, 60, 67, 0.6);
}

.orders-table-body .list {
	display: flex;
  align-items: center;
  min-height: 44px;
  border-radius: 10px;
	background-color: white;
  cursor: pointer;
}
